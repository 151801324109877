exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-board-of-directors-js": () => import("./../../../src/pages/about-us/board-of-directors.js" /* webpackChunkName: "component---src-pages-about-us-board-of-directors-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-medicinal-chemistry-advisory-board-js": () => import("./../../../src/pages/about-us/medicinal-chemistry-advisory-board.js" /* webpackChunkName: "component---src-pages-about-us-medicinal-chemistry-advisory-board-js" */),
  "component---src-pages-about-us-our-investors-js": () => import("./../../../src/pages/about-us/our-investors.js" /* webpackChunkName: "component---src-pages-about-us-our-investors-js" */),
  "component---src-pages-about-us-our-scientific-founders-js": () => import("./../../../src/pages/about-us/our-scientific-founders.js" /* webpackChunkName: "component---src-pages-about-us-our-scientific-founders-js" */),
  "component---src-pages-about-us-our-team-js": () => import("./../../../src/pages/about-us/our-team.js" /* webpackChunkName: "component---src-pages-about-us-our-team-js" */),
  "component---src-pages-about-us-scientific-advisory-board-js": () => import("./../../../src/pages/about-us/scientific-advisory-board.js" /* webpackChunkName: "component---src-pages-about-us-scientific-advisory-board-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-science-js": () => import("./../../../src/pages/our-science.js" /* webpackChunkName: "component---src-pages-our-science-js" */),
  "component---src-pages-pdf-viewer-js": () => import("./../../../src/pages/pdf-viewer.js" /* webpackChunkName: "component---src-pages-pdf-viewer-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

